function SvgFacebook(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 33 33" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#prefix__clip0_1015:358)">
        <path d="M16.844.5C7.923.5.687 7.665.687 16.5s7.236 16 16.157 16c8.922 0 16.157-7.165 16.157-16S25.766.5 16.844.5zM12.15 24.688H8.214V12.963h3.935v11.723zm-1.967-13.325h-.026c-1.32 0-2.174-.9-2.174-2.025 0-1.15.88-2.025 2.226-2.025s2.175.875 2.2 2.025c0 1.125-.854 2.025-2.226 2.025zm16.156 13.325h-3.934v-6.272c0-1.576-.57-2.651-1.994-2.651-1.087 0-1.734.725-2.019 1.425-.104.25-.13.6-.13.95v6.547h-3.934s.052-10.623 0-11.723h3.935v1.66c.523-.799 1.458-1.935 3.546-1.935 2.589 0 4.53 1.675 4.53 5.276v6.723z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1015:358">
          <path fill="#fff" transform="translate(.688 .5)" d="M0 0h32.314v32H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgFacebook
