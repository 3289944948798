import { SVGProps } from 'react'

const FacebookOutlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 1.5a.5.5 0 0 0-.5-.5H9.25A4.25 4.25 0 0 0 5 5.25V7H3.25a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5H5v5.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5V11h1.75a.5.5 0 0 0 .485-.379l.75-3A.5.5 0 0 0 11.5 7H9V5.25A.25.25 0 0 1 9.25 5h2.25a.5.5 0 0 0 .5-.5v-3Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default FacebookOutlineIcon
