import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import classNames from 'classnames'
import Social from './Social'
import { PAGES_SLUG } from '@/constants/pages'

const Footer = () => {
  const menuTextClass = classNames(
    'subheading-5 md:heading-6 lg:subheading-5 hover:text-blue-500 transition'
  )
  return (
    <footer className="flex flex-col" id="footer">
      <div className="py-8 space-y-8 base-container">
        <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:items-center md:justify-between">
          <Link to="/" className="max-w-[200px]">
            <StaticImage
              src="../../../images/logo/footer.png"
              alt="Vietnam Health Literacy Logo"
              formats={['auto', 'avif', 'webp']}
              placeholder="blurred"
              width={300}
              quality={100}
            />
          </Link>
          <div className="flex space-x-6">
            <Social
              type="Facebook"
              href="https://www.facebook.com/vhlproject"
            />
            <Social
              type="LinkedIn"
              href="https://www.linkedin.com/company/vietnam-health-literacy"
            />
            <Social
              type="Youtube"
              href="https://www.youtube.com/channel/UCEWhYKldGxi8l8qJOKG3FWw"
            />
          </div>
        </div>
        <hr />
        <div className="flex flex-col space-y-12 md:flex-row md:items-center md:justify-between md:space-x-8 md:space-y-0">
          <div className="flex flex-col space-y-6">
            <Link to={PAGES_SLUG.about} className={menuTextClass}>
              Về chúng tôi
            </Link>
            <Link to={PAGES_SLUG.career} className={menuTextClass}>
              Tuyển dụng
            </Link>
          </div>
          <div className="flex flex-col space-y-6">
            <Link to={PAGES_SLUG.contact} className={menuTextClass}>
              Liên hệ
            </Link>
            <Link to={PAGES_SLUG.editorial_process} className={menuTextClass}>
              Chính sách biên tập
            </Link>
          </div>
          <div className="flex flex-col justify-start space-y-6">
            <Link to={PAGES_SLUG.advertising_policy} className={menuTextClass}>
              Chính sách quảng cáo
            </Link>
            <Link to={PAGES_SLUG.privacy_policy} className={menuTextClass}>
              Chính sách bảo mật & sử dụng
            </Link>
          </div>
        </div>
        <hr />
        <div className="py-8 text-center md:py-0 caption">
          <p>© 2021 Vietnam Health Literacy.</p>
          <p> All Rights Reserved.</p>
          <p>
            Các thông tin trên Vietnam Health Literacy chỉ dành cho mục đích
            tham khảo, tra cứu và không thay thế cho việc chẩn đoán hoặc điều
            trị y khoa.
          </p>
          <p>Cần tuyệt đối tuân theo hướng dẫn của Bác sĩ và Nhân viên y tế.</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
