exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-chinh-sach-bao-mat-tsx": () => import("./../../../src/pages/chinh-sach-bao-mat.tsx" /* webpackChunkName: "component---src-pages-chinh-sach-bao-mat-tsx" */),
  "component---src-pages-chinh-sach-bien-tap-tsx": () => import("./../../../src/pages/chinh-sach-bien-tap.tsx" /* webpackChunkName: "component---src-pages-chinh-sach-bien-tap-tsx" */),
  "component---src-pages-chinh-sach-quang-cao-tsx": () => import("./../../../src/pages/chinh-sach-quang-cao.tsx" /* webpackChunkName: "component---src-pages-chinh-sach-quang-cao-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lien-he-tsx": () => import("./../../../src/pages/lien-he.tsx" /* webpackChunkName: "component---src-pages-lien-he-tsx" */),
  "component---src-pages-podcast-tsx": () => import("./../../../src/pages/podcast.tsx" /* webpackChunkName: "component---src-pages-podcast-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-tuyen-dung-tsx": () => import("./../../../src/pages/tuyen-dung.tsx" /* webpackChunkName: "component---src-pages-tuyen-dung-tsx" */),
  "component---src-pages-ve-chung-toi-tsx": () => import("./../../../src/pages/ve-chung-toi.tsx" /* webpackChunkName: "component---src-pages-ve-chung-toi-tsx" */),
  "component---src-templates-post-index-tsx": () => import("./../../../src/templates/post/index.tsx" /* webpackChunkName: "component---src-templates-post-index-tsx" */),
  "component---src-templates-series-index-tsx": () => import("./../../../src/templates/series/index.tsx" /* webpackChunkName: "component---src-templates-series-index-tsx" */),
  "component---src-templates-topic-index-tsx": () => import("./../../../src/templates/topic/index.tsx" /* webpackChunkName: "component---src-templates-topic-index-tsx" */)
}

