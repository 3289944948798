function SvgFacebook(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 33" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#prefix__clip0_1015:360)">
        <path d="M14.008 19.497l5.204-2.997-5.204-2.998v5.995z" />
        <path d="M16 .5C7.165.5 0 7.665 0 16.5s7.165 16 16 16 16-7.165 16-16-7.165-16-16-16zm9.998 16.016s0 3.245-.412 4.81a2.505 2.505 0 01-1.762 1.762C22.258 23.5 16 23.5 16 23.5s-6.242 0-7.823-.428a2.506 2.506 0 01-1.763-1.763c-.412-1.548-.412-4.809-.412-4.809s0-3.245.412-4.81a2.556 2.556 0 011.763-1.778C9.74 9.5 16 9.5 16 9.5s6.259 0 7.823.428c.857.23 1.532.906 1.763 1.763.428 1.564.412 4.825.412 4.825z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_1015:360">
          <path fill="#fff" transform="translate(0 .5)" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgFacebook
