import './src/styles/global.css'
import '@fontsource/darker-grotesque/700.css'
import '@fontsource/darker-grotesque/600.css'
import '@fontsource/open-sans/700.css'
import '@fontsource/open-sans/400.css'
import * as React from 'react'
import DefaultLayout from './src/layouts/DefaultLayout'

export function wrapPageElement({ element, props }) {
  const Layout = element.type.Layout ?? DefaultLayout
  return <Layout {...props}>{element}</Layout>
}
