import React from 'react'
import FixedNavbar from '../components/shared/Navbar/FixedNavbar'
import Footer from '../components/shared/Footer'

const DefaultLayout = ({ children }: { children?: React.ReactChildren }) => {
  return (
    <>
      <FixedNavbar />
      {children}
      <hr />
      <Footer />
    </>
  )
}

export default DefaultLayout
