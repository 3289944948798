function ZaloIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M32 23.77v3.852A4.377 4.377 0 0127.622 32H4.378A4.377 4.377 0 010 27.622V4.378A4.378 4.378 0 014.378 0h4.935l-.306.24a11.863 11.863 0 00-4.378 5.648 18.233 18.233 0 00-1.149 4.99 19.25 19.25 0 001.27 9.193c.296.72.662 1.41 1.094 2.058a2.43 2.43 0 01.296 2.243 4.377 4.377 0 01-.964 1.664c-.087.109-.197.218 0 .328a8.055 8.055 0 003.864-.646.372.372 0 01.383 0 17.03 17.03 0 006.161 2.266c1.539.27 3.101.376 4.662.317a19.042 19.042 0 008.832-2.32 10.715 10.715 0 002.758-2.189.099.099 0 01.066 0L32 23.77z"
        fill="#919191"
      />
      <path
        d="M32.002 4.378V23.77h-.12a.099.099 0 00-.066 0 10.716 10.716 0 01-2.758 2.189 19.043 19.043 0 01-8.832 2.32 22.188 22.188 0 01-4.662-.317 17.03 17.03 0 01-6.161-2.266.372.372 0 00-.383 0 8.055 8.055 0 01-3.864.646c-.197-.11-.087-.219 0-.328.44-.477.77-1.045.963-1.664a2.43 2.43 0 00-.295-2.243 11.84 11.84 0 01-1.094-2.058 19.25 19.25 0 01-1.27-9.193 18.232 18.232 0 011.204-4.968A11.863 11.863 0 019.04.24L9.315 0h18.31a4.377 4.377 0 014.377 4.378z"
        fill="#FDFDFD"
      />
      <path
        d="M11.81 11.94c-.088.109-.132.175-.186.23-1.292 1.619-2.594 3.283-3.896 4.847a1.543 1.543 0 00-.372 1.03c0 .25 0 .273.284.273h5.593a.547.547 0 00.503-.515v-.437c0-.143 0-.296-.285-.296h-4.18l.219-.284 3.841-4.838c.25-.31.392-.695.405-1.094 0-.274 0-.296-.285-.296H7.815c-.262 0-.284 0-.273.285v.744c0 .307 0 .329.306.329h3.962v.022zM22.477 14.403v-3.688c0-.153-.054-.219-.218-.219h-.909c-.164 0-.219.055-.219.219v7.135a.428.428 0 00.449.471h.58c.197 0 .306 0 .306-.285l.011-3.633z"
        fill="#fff"
      />
      <path
        d="M11.807 11.94H7.846c-.296 0-.318 0-.307-.33v-.743c0-.263 0-.285.274-.285h5.636c.274 0 .296 0 .285.295-.013.4-.155.784-.405 1.095l-3.842 4.793-.219.285h4.181c.274 0 .285 0 .285.295v.438a.547.547 0 01-.504.514H7.66c-.252 0-.274 0-.285-.273a1.543 1.543 0 01.372-1.029c1.303-1.62 2.605-3.283 3.896-4.848.033-.033.077-.11.164-.208zM18.79 17.827a3.02 3.02 0 01-4.148-4.268 2.89 2.89 0 011.948-1.215 2.954 2.954 0 012.188.493c0-.317.077-.34.394-.329h.624c.164 0 .252 0 .24.22v5.395c0 .186-.054.262-.25.24h-.395a.525.525 0 01-.58-.393.466.466 0 000-.077l-.022-.066zm-3.47-2.506a1.772 1.772 0 101.773-1.784 1.784 1.784 0 00-1.773 1.784zM26.375 12.268a3.064 3.064 0 11-3.031 3.053 3.053 3.053 0 013.031-3.053zm0 4.848a1.794 1.794 0 10-1.784-1.795 1.772 1.772 0 001.784 1.795zM22.478 14.403v3.633c0 .274 0 .296-.306.285-.307-.011-.383 0-.58 0a.427.427 0 01-.45-.47v-.165-6.971c0-.164.056-.219.22-.219h.908c.164 0 .219.066.219.219v3.688h-.01z"
        fill="#919191"
      />
    </svg>
  )
}

export default ZaloIcon
