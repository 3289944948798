import loadable from '@loadable/component'

const DesktopFixedNavbar = loadable(() => import('./DesktopFixedNavbar'))
const MobileFixedNavbar = loadable(() => import('./MobileFixedNavbar'))

const FixedNavbar = () => {
  return (
    <>
      <MobileFixedNavbar />
      <DesktopFixedNavbar />
    </>
  )
}

export default FixedNavbar
