function SvgFacebook(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M27.622 0H4.378A4.378 4.378 0 000 4.378v23.244A4.378 4.378 0 004.378 32h23.244A4.378 4.378 0 0032 27.622V4.378A4.378 4.378 0 0027.622 0z" />
      <path
        d="M22.215 21.154l.766-4.63h-4.498v-2.998a2.31 2.31 0 012.605-2.495h2.025V7.09c-1.184-.195-2.38-.3-3.579-.318-3.655 0-6.052 2.19-6.052 6.228v3.524h-4.06v4.629h4.06v10.845h5.001V21.154h3.732z"
        fill="#FDFDFD"
      />
    </svg>
  )
}

export default SvgFacebook
